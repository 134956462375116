// Only inline type now. Write the new condition to add new popup types

const makePopup = (selector, type) => {

    const $selector = $(selector);

    if (type === 'inline') {
        $selector.magnificPopup({
            src: $(this).attr('href'),
            type: type,
            removalDelay: 300,
            midClick: true,
            callbacks: {
                beforeOpen: function () {
                    this.st.mainClass = 'mfp-zoom-out';

                    if($selector.hasClass('js-video-popup')){

                        $($selector.attr('href')).find('video')[0].play()

                    }
                },

                beforeClose: function () {
                    if($selector.hasClass('js-video-popup')){

                        $($selector.attr('href')).find('video')[0].pause()

                    }

                },

                close: function () {
                    $('.popup-window-image').removeClass('flipped');
                }
            },
        })
    } else {
        console.log('Write condition for this popup type in make_popup, please');
    }

};

export default makePopup;
