import * as jQuery from 'jquery';

// import hamburgerMenu from './components/menu';
import './libs/magnificPopup/jquery.magnific-popup.min';
import './libs/owlCarousel/owl.carousel';
import makePopup from './components/make-popup';

window.$ = jQuery.default;

((html) => {
    html.className = html.className.replace(/\bno-js\b/, 'js');
})(document.documentElement);

$(() => {

    makePopup('.js-popup', 'inline');

    // change close-btn color in mobile
    $('.popup-window-image').on('click', function () {
        $(this).toggleClass('flipped');
        if($(window).width() < 992) {
            $(this).parents('.popup-window').find('.mfp-close').toggleClass('light');
        }
    });

    // full width
    setTimeout(function () {
        $('.stories').parents('.t396__elem').css({'width': '100%', 'left': 0});
        let height = $('.stories').outerHeight();
        $('.stories').parents('.t396__artboard').css('height', height);

    },1000);
    
    $(window).on('resize', function () {
        setTimeout(function () {
            $('.stories').parents('.t396__elem').css({'width': '100%', 'left': 0});
            let height = $('.stories').outerHeight();
            $('.stories').parents('.t396__artboard').css('height', height);
        },1000);
    });

    // url
    let url = new URL(window.location.href);

    if(url.hash) {
        let name = url.hash.replace('#', '');
        if( name == 'andrey' || name == 'anya'  || name == 'nazar' || name == 'nastya' || name == 'olga' || name == 'sasha-misha'
            || name == 'yaroslav' || name == 'ivan' || name == 'anton' ) {
            ga('send', {hitType: 'event', eventCategory: 'qr', eventAction: 'open_story', eventLabel: name});
            $('#' + name).trigger('click');
            $('.popup-window-image').addClass('flipped');
            if($(window).width() < 992) {
                $('.mfp-close').addClass('light');
            }
        }
    }

    // slider
    $('.js-timeline-slider').owlCarousel({
        loop: true,
        margin: 0,
        nav: true,
        dots: false,
        items: 1
    });

    if($(window).width() < 992) {
        $('.js-timeline-slider').trigger('destroy.owl.carousel');
    }

    // more cities
    $('.timeline-btn-more').on('click touchstart', function () {
        $('.timeline-slider').addClass('all');
    });

    $('.timeline-btn-less').on('click touchstart', function () {
        $('.timeline-slider').removeClass('all');
    });

    //fixed header
    $(window).on('scroll', function(){
        let scroll = $(window).scrollTop();

        if( scroll > 0 ) {
            $('#t-header').addClass('fixed');
        } else {
            $('#t-header').removeClass('fixed');
        }
    });

    if( $(window).scrollTop() > 0 ) {
        $('#t-header').addClass('fixed');
    } else {
        $('#t-header').removeClass('fixed');
    }

    // scroll to selected section
    $('#t-header a.tn-atom').on('click',function (e) {
        if(this.hash) {
            e.preventDefault();
        }

        let target = this.hash,
            $target = $(target);

        $('html, body').stop().animate({
            'scrollTop': $target.offset().top
        }, 800, 'swing');
    });

});
